import React, { useState, useEffect } from "react";
import { Paper, Tabs, Tab, Typography, Box } from "@mui/material";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

// Custom hook to parse query parameters from the URL
function useQuery() {
  const { search } = useLocation();
  // Replace additional question marks with ampersands, except for the first one
  return React.useMemo(
    () => new URLSearchParams(search.replace(/\?/g, "&").replace("&", "?")),
    [search]
  );
}

// Component that renders on /login link
const SignInOutContainer = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const query = useQuery();

  // Extract signup parameter to determine the initial tab
  const isSignUp = query.get("signup") === "true";
  const [value, setValue] = useState(isSignUp ? 1 : 0);

  // Extract the source parameter and store it in localStorage
  useEffect(() => {
    const source = query.get("source");
    if (source) {
      localStorage.setItem("registrationSource", source);
    }
  }, [query]);

  // Retrieve source from localStorage (or default to "direct")
  const source = localStorage.getItem("registrationSource") || "direct";

  // Handle redirection based on changes to currentUser
  useEffect(() => {
    if (currentUser) {
      const locationState = location.state;
      const from = locationState?.from || { pathname: "/", search: "" };
      const { pathname, search } = from;
      navigate(`${pathname}${search}`, { replace: true });
    }
  }, [currentUser, navigate, location.state]);

  // handle navigation after sign in or sign up
  // if user clicked sign in from viewing a course,
  // this will take them back to the course they were viewing when clicked sign in
  // if no, nav to home page ("/")
  const navigateAfterSignInOrSignUp = () => {
    if (currentUser) {
      const locationState = location.state;
      const from = locationState?.from || { pathname: "/", search: "" };
      const { pathname, search } = from;
      navigate(`${pathname}${search}`, { replace: true });
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Handle clicking the create account button
  const handleCreateAccountClick = () => {
    setValue(1);
  };

  const paperStyle = {
    width: window.innerWidth > 450 ? "500px" : "fit-content",
    margin: "20px auto",
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <span
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </span>
    );
  }

  const checkLocation = () => {
    return window.location.href.includes("/login?signup=true");
  };

  useEffect(() => {
    checkLocation();
  }, []);

  return (
    <>
      {checkLocation() && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-11021658284"
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-11021658284');`}
          </script>
          <script>
            {`
      function gtag_report_conversion(url) {
				var callback = function () {
					if (typeof(url) != 'undefined') {
						window.location = url;
					}
				};
				gtag('event', 'conversion', {
						'send_to': 'AW-11021658284/4QBdCOytiqUZEKzRxIcp',
						'event_callback': callback
				});
				return false;
			}			
    `}
          </script>

          <script>{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"343110529", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</script>
        </Helmet>
      )}
      <Paper elevation={20} style={paperStyle}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
          variant="fullWidth"
        >
          <Tab label={t("signInOutContainer.signIn")} />
          <Tab label={t("signInOutContainer.signUp")} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <SignIn
            handleCreateAccountClick={handleCreateAccountClick}
            navigateAfterSignInOrSignUp={navigateAfterSignInOrSignUp}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SignUp
            navigateAfterSignInOrSignUp={navigateAfterSignInOrSignUp}
            source={source}
          />
        </TabPanel>
      </Paper>
    </>
  );
};

export default SignInOutContainer;
