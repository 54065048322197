import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HomeBanner from "./home/HomeBanner";
import HomeButtons from "./home/HomeButtons";
import "./home/Home.scss";
import {
  getCurrentUserObject,
  updatePracticeTypeForUser,
} from "./helpers/source";
import { useAuth } from "../contexts/AuthContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUpdatingPendingPharmacyRequests } from "../features/states/pendingPharmacyRequestsSlice";
import InitialLinkingPharmacySetUpModal from "../pendingPharmacyRequests/InitialLinkingPharmacySetUpModal";

export default function Homepage({ handleOpenAIButton }) {
  const { currentUser } = useAuth();
  const [userObject, setUserObject] = useState(null);
  const [showPinSetupPrompt, setShowPinSetupPrompt] = useState(false);
  const [
    showInitialLinkingPharmacySetUpModal,
    setShowInitialLinkingPharmacySetUpModal,
  ] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      dispatch(getUpdatingPendingPharmacyRequests(currentUser.uid));
    }
  }, [dispatch]);

  const handleSetupPin = () => {
    // close PIN setup prompt
    setShowPinSetupPrompt(false);
    // navigate to UserProfilePage and signal to open the AccountSettingsModal
    navigate("/profile", { state: { promptPinSetup: true } });
  };

  useEffect(() => {
    if (currentUser) {
      getCurrentUserObject(currentUser.uid)
        .then((res) => {
          if (!res.profession || res.profession.trim() === "") {
            // if no profession present, update users profession to 'Pharmacist'
            // early users don't have profession set for them since site was originally built for pharmacists only
            updatePracticeTypeForUser({
              userId: currentUser.uid,
              profession: "Pharmacist",
            })
              .then(() => {
                // update local state with the new profession (pharmacist)
                setUserObject({ ...res, profession: "Pharmacist" });
              })
              .catch((error) => {
                console.error("Error updating profession", error);
              });
          } else {
            setUserObject(res);
          }
          if (!res.doNotShowInitPopUp) {
            setShowInitialLinkingPharmacySetUpModal(true);
          }

          // Check if user needs to set up PIN (they are a pharmacist with no pin set)
          // if (res.profession.toLowerCase() === "pharmacist" && !res.myPin) {
          // setShowPinSetupPrompt(true);
          // }
        })
        .catch((error) => {
          console.error("Error fetching user object", error);
        });
    }
  }, [currentUser]);

  // Extractregistration source from URL and store in localStorage
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get("source");
    if (source) {
      localStorage.setItem("registrationSource", source);
    }
  }, [location.search]);

  return (
    <>
      <HomeBanner userObject={userObject} />
      {/* <BlogPromoBanner /> */}
      <HomeButtons
        handleOpenAIButton={handleOpenAIButton}
        userObject={userObject}
      />
      {/*  Modal for setting up PIN  */}
      <InitialLinkingPharmacySetUpModal
        open={showInitialLinkingPharmacySetUpModal}
        setShowInitialLinkingPharmacySetUpModal={
          setShowInitialLinkingPharmacySetUpModal
        }
        userObject={userObject}
      />
      <Dialog
        open={showPinSetupPrompt}
        onClose={() => setShowPinSetupPrompt(false)}
      >
        <DialogTitle>
          Enhance Your MedEssist Experience with a Personal PIN
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you're part of a pharmacy that uses a MedEssist account, setting
            up a personal PIN on the Hub allows you to authorize and sign off
            certain actions seamlessly while at work.
            <br />
            Would you like to set up your PIN now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPinSetupPrompt(false)}>Not now</Button>
          <Button onClick={handleSetupPin} autoFocus>
            Let's do it!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
